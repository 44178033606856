:root {
  color-scheme: light dark;

  --main-background: white;
  --red-color: #9a3323;
  --default-font-color: #111111;
  --light-gray: #cccccc;
  --dark-gray: #999999;
  --dark-dark-gray: #555555;
  --light-light-gray: #f3f6f7;
  --apple-item-gray: rgba(118, 120, 119, .065);
  /* --apple-item-gray: rgba(118, 120, 119, .035); */
  --secondary-color: #aaff09;
  --link-color: #0000ee;
  --box-shadow-color: rgba(0, 0, 0, .1);
  --button-color: rgba(210, 210, 210, .5);
  --button-color-selected: rgba(170, 170, 170, .9);
  --onscreen-button-color: rgba(210, 210, 210, .75);
  --mobile-expander-gradient-color-0: rgba(255, 255, 255, 1);
  --mobile-expander-gradient-color-1: rgba(255, 255, 255, .88 );
  --heat-gradient: linear-gradient(95deg, #af00ff 0%, #0000ff 16.67%, #00c3c3 33.33%, #00dd00 50%, #e0e000 66.67%, #ff8000 83.33%, #b90000 100%);
}

@media (prefers-color-scheme: dark) {
  :root {
    --main-background: black;
    --red-color: #9a3323;
    --default-font-color: #eeeeee;
    --light-gray: #555555;
    --dark-gray: #777777;
    --dark-dark-gray: #999999;
    --light-light-gray: #2b2b2b;
    --apple-item-gray: rgba(107, 105, 106, .25);
    /* --secondary-color: #4400f6; */
    --link-color: #ffff11;
    --box-shadow-color: rgba(25, 25, 25, .85);
    --button-color: rgba(255, 255, 255, .25);
    --button-color-selected: rgba(255, 255, 255, .35);
    --onscreen-button-color: rgba(255, 255, 255, .5);
    --mobile-expander-gradient-color-0: rgba(0, 0, 0, 1);
    --mobile-expander-gradient-color-1: rgba(0, 0, 0, .88);
    --heat-gradient: linear-gradient(95deg, #bf00ff 0%, #3f3fff 16.67%, #00ffff 33.33%, #00ff00 50%, #ffff00 66.67%, #ff8000 83.33%, #b90000 100%);
  }
}
