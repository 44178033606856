@import '~bootstrap/scss/bootstrap.scss'
@import './theme.css'
@import './outdated.sass'

@font-face
  font-family: 'Arc'
  src: url('./fonts/ALSArc-Light.ttf') format('truetype')
@font-face
  font-family: 'Arc-Bold'
  src: url('./fonts/ALSArc-Bold.ttf') format('truetype')

$red: var(--red-color)
$light: var(--main-background)
$dark: var(--default-font-color)
$light-gray: var(--light-gray)
$dark-gray: var(--dark-gray)
$dark-dark-gray: var(--dark-dark-gray)
$light-light-gray: var(--light-light-gray)
$apple-item-gray: var(--apple-item-gray)
$secondary: var(--secondary-color)
$link-color: var(--link-color)
$box-shadow-color: var(--box-shadow-color)
$box-shadow: 0 5px 15px $box-shadow-color
$button-color: var(--button-color)
$button-color-selected: var(--button-color-selected)
$onscreen-button-color: var(--onscreen-button-color)
$mobile-expander-gradient-color-0: var(--mobile-expander-gradient-color-0)
$mobile-expander-gradient-color-1: var(--mobile-expander-gradient-color-1)
$mobile-expander-gradient: linear-gradient($mobile-expander-gradient-color-0, $mobile-expander-gradient-color-1)
$heat-gradient: var(--heat-gradient)

$input-height: 32px 
$default-border-radius: 5px

$mobile-expander-area-height: 30px
$mobile-expander-button-width: 40px
$mobile-expander-button-height: 5px

// $regular-font-size: $regular-font-size
$regular-font-size: 15px //for Arc font
  
$node-change-transition: .3s

*
  // font-family: -apple-system, system-ui, Roboto, "Open Sans", "Helvetica Neue", sans-serif
  // font-family: 'SPb', -apple-system, system-ui, Roboto, "Open Sans", "Helvetica Neue", sans-serif
  font-family: 'Arc', -apple-system, system-ui, Roboto, "Open Sans", "Helvetica Neue", sans-serif
  font-weight: normal
  color: $dark
  outline: none
  box-sizing: border-box
  margin: 0
  padding: 0
  line-height: 1
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

body, html
  background: $light
//   font-size: 16px
  // font-size: 1em
  

a
  text-decoration: none
  color: $link-color
p
  margin-bottom: 0
  
h1
  // font-size: 32px
  font-size: 2em
h2
  // font-size: 24px
  font-size: 1.5em
h3
  // font-size: 18px
  font-size: 1.125em
h4
  // font-size: 16px
  font-size: 1em

h1, h2, h3
  b
    font-weight: 700
span
  // font-size: 15px
  font-size: .9375em
p
  // font-size: 14px
  font-size: .875em
small
  // font-size: 13px
  font-size: .8125em

textarea
  width: 100%

body, html
  position: fixed

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield


.h1, .h2, .h3
  font-family: 'Arc-Bold', -apple-system, system-ui, Roboto, "Open Sans", "Helvetica Neue", sans-serif
.h1
  font-size: $regular-font-size * 3
.h2
  font-size: $regular-font-size * 2
.h3
  font-size: $regular-font-size * 1.5

.gradient-text-animation-0
  background-size: 300% 300% !important
  animation: gradientTextAnimation0 5s ease infinite
  @keyframes gradientTextAnimation0
    0%
      background-position: 0% 50%
    50%
      background-position: 100% 50%
    100%
      background-position: 0% 50%
.gradient-text-animation-1
  background-size: 300% 300% !important
  animation: gradientTextAnimation1 5s ease infinite
  @keyframes gradientTextAnimation1
    0%
      background-position: 100% 50%
    50%
      background-position: 0% 50%
    100%
      background-position: 100% 50%

.gradient-text
  background: $heat-gradient
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent


.noselect
  -webkit-touch-callout: none  /* iOS Safari */
  -webkit-user-select: none    /* Safari */
  -khtml-user-select: none     /* Konqueror HTML */
  -moz-user-select: none       /* Firefox */
  -ms-user-select: none        /* Internet Explorer/Edge */
  user-select: none            /* Non-prefixed version, currently supported by Chrome and Opera */

.theme-change-transition
  transition: color 1s, background-color 1s, border-color 1s
    
.App
  max-height: 100vh
  display: flex
  flex-direction: column
  align-items: stretch
  .content
    flex-grow: 1
    flex-shrink: 0
    display: flex
    flex-direction: column
    align-items: stretch

.smart-content
  position: absolute
  width: 100%
  height: 100%
  top: 0
  bottom: 0


//ITERFACE BLOCK
$interface-block-margin: 8px
$interface-block-padding: 8px
.interface-block
  width: calc(100% - 2 * #{$interface-block-margin})
  margin: $interface-block-margin $interface-block-margin 0 $interface-block-margin
  position: relative
  &__backdrop
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(255, 255, 255, .75)
    border-radius: $default-border-radius
  &__content
    padding: $interface-block-padding
    position: relative

//REDO THIS SHIT
.button
  @extend .noselect
  border: none
  cursor: pointer
  outline: none !important
  display: inline
  line-height: 1.2
  padding: 5px 10px 5px 4px
  border-radius: 6px
  background-color: transparent
  transition: background-color .1s
  margin-bottom: 8px
  margin-left: 10px
  &--active
    transition: background-color .1s
    background-color: $button-color
  &--load-more
    @extend .button
    width: 100%
    height: 30px
    color: $dark
    // background-color: $light-gray
    background-color: $button-color
    // margin-left: 5%
    margin-left: 0
    margin-top: 8px
    font-size: $regular-font-size



$text-interface-width: 375px

//VIEWER
$canvas-offset: 40px
.page-container
  position: relative
  width: 100vw
  height: 100vh
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  background-color: $light
  @media (max-width: 1024px)
    flex-direction: column
  .Viewer,
  .interface-container
    position: absolute
  .viewer-container
    width: calc(100% - #{$text-interface-width})
    @media (max-width: 1024px)
      width: 100%
    // height: 100%
    // bottom: 0
    // right: 0
    flex-grow: 1
    overflow: hidden
    position: relative
  .Viewer
    width: 100%
    height: 100%
    bottom: 0
    right: 0
  .interface-container
    z-index: 2
    width: 20%
    min-width: 800px
    height: 100%
    bottom: 0
    right: 0
    overflow: hidden
    .backdrop-blur
      position: absolute
      width: calc(100vw + 2 * #{$canvas-offset})
      height: calc(100vh + 2 * #{$canvas-offset})
      opacity: .99
      overflow: hidden
      bottom: -$canvas-offset
      right: -$canvas-offset
      canvas
        filter: blur(8px)
      &::after
        width: 100%
        height: 100%
        position: absolute
        content: ""
        bottom: 0
        right: 0
        background-color: rgba(125, 125, 125, .5)
        z-index: 4
    .interface
      position: absolute
      width: 100%
      height: 100%
      top: 0
      left: 0
      z-index: 5




$arrow-size: 7px

.rating
  &-row
    width: 100%
    display: table
    padding: 3px 10px
    background: white
    border-bottom: 1px solid #c1c1c1
    border-left: 1px solid #c1c1c1
    &__index
      width: 20px
      float: left
      font-size: 12px
      color: grey
      padding-top: 2px
    &__name
      width: 200px
      float: left
      position: relative
      padding-left: 20px
      font-size: $regular-font-size
    &__connections
      width: 25px
      float: right
      font-size: $regular-font-size
      font-weight: 600
      color: #110cd7
    &__addition
      width: 35px
      min-height: 1px
      float: right
      font-size: $regular-font-size
      color: green
  &-row.up, &-row.down, &-row.neutral
    .rating-row__name::before
      position: absolute
      content: ""
      top: 4px
      left: 0
      width: 0
      height: 0
      border-style: solid
  &-row.up .rating-row__name::before
      border-width: 0 $arrow-size $arrow-size $arrow-size
      border-color: transparent transparent green transparent
      transform: scale(0.66, 1)
  &-row.down .rating-row__name::before
      border-width: $arrow-size $arrow-size 0 $arrow-size
      border-color: red transparent transparent transparent
      transform: scale(0.66, 1)
  &-row.neutral .rating-row__name::before
      width: $arrow-size * 1.15
      height: $arrow-size * 1.15
      border-radius: $arrow-size
      background-color: #aaaaaa
      border: 0
      left: 3px
      top: 3px










.text-interface
  width: $text-interface-width
  // transition: width .15s
  height: 100%
  background-color: $light
  z-index: 10000
  position: relative
  @media (min-width: 1025px)
    box-shadow: -2px 0 35px $box-shadow-color
    @media (prefers-color-scheme: dark)
      box-shadow: 12px -12px 25px $box-shadow-color
      border-right: 1px solid var(--light-gray)
    // overflow-y: hidden
  @media (max-width: 1024px)
    width: 100%
    height: auto
    // box-shadow: 0 10px 12px rgba(0, 0, 0, .15)
  &__search-bar
    padding: 10px
  .node-info
    &__emoji
      font-size: 56px
      padding: 6px 12px 12px
    &__tags
      width: 100%
      line-height: 22px
      margin-bottom: 3px
      // padding: 0 5px
      margin-bottom: 0px
      display: table
      &:not(:first-child)
        margin-top: 6px
      &__item
        @extend .button
        @extend .button--active
        margin: 0 8px 8px 0
        display: inline-block
        float: left
        padding-left: 10px
        &--emoji
          padding: 0
          width: 66px
          height: 66px
          font-size: 50px
          line-height: 1
          transition: background-color $node-change-transition
          .emoji
            // padding-left: 0
          .emoji__img
            padding-left: 5px
    &__connections
      // margin-bottom: 12px
      // padding-left: 5px
  &__content
    padding: 0px 11px 50px
  &--spread
    width: $text-interface-width * 2
  &__spread-button
    @media (max-width: 1024px)
      display: none
    @extend .button
    position: absolute
    width: $mobile-expander-area-height
    top: 0
    right: calc(-#{$mobile-expander-area-height})
    height: 100%
    cursor: pointer
    opacity: .5
    &:hover
      opacity: .9
    &::after, &::before
      position: absolute
      right: calc((#{$mobile-expander-area-height} - #{$mobile-expander-button-height}) / 2)
      content: ""
      border-radius: $mobile-expander-button-height * 2
      background-color: $dark-gray
      height: calc(#{$mobile-expander-button-width} / 2 + #{$mobile-expander-button-height} / 1.1)
      width: $mobile-expander-button-height
      transition: transform .08s
    &::before
      bottom: calc((100% - #{$mobile-expander-button-width} - #{$mobile-expander-button-height}) / 2)
      transform-origin: center right
    &::after
      top: calc((100% - #{$mobile-expander-button-width} - #{$mobile-expander-button-height}) / 2)
      transform-origin: center left
    &--opened
      &::before
        transform: rotate(-15deg) translate(calc(-#{$mobile-expander-button-height} / 2), 0)
      &::after
        transform: rotate(15deg) translate(calc(-#{$mobile-expander-button-height} / 2), 0)
    &--closed
      &::before
        transform: rotate(15deg) translate(calc(#{$mobile-expander-button-height} / 2), 0)
      &::after
        transform: rotate(-15deg) translate(calc(#{$mobile-expander-button-height} / 2), 0)


.username-link
  width: 100%
  // max-width: 500px
  line-height: 21px
  font-size: $regular-font-size
  display: flex
  flex-direction: row
  &:nth-child(odd)
    // background-color: $apple-item-gray

  &__type
    width: 30px    
    line-height: inherit
    font-size: inherit
  &__number
    width: 55px
    text-align: right
    padding-right: 7px
    color: inherit
    line-height: inherit
    font-size: inherit
  &__name
    flex-grow: 1
    font-size: inherit
    line-height: inherit
    color: inherit
    .link
      font-size: inherit
      line-height: inherit
      // color: $link-color
      color: inherit
      text-decoration: underline
      cursor: pointer
      .emoji
        margin-left: 5px
  &__date
    line-height: inherit
    font-size: inherit
    color: $dark-gray
  &--simple
    display: inline
    line-height: 1.8
    .link
      font-size: $regular-font-size
      color: $dark-dark-gray
      // color: inherit
      text-decoration: underline
      cursor: pointer
  &--sort
    cursor: pointer
    border-bottom: 1px solid $light-gray
    padding-bottom: 5px
    *
      @extend .noselect
    .asc-order,
    .desc-order
      &::after
        content: ""
        display: inline-block
        width: 5px
        height: 5px
        border-right: 2px solid $light-gray
        border-bottom: 2px solid $light-gray
        margin-left: .25em
    .asc-order
      &::after
        transform: translateY(-.1em) rotate(-135deg)
    .desc-order
      &::after
        transform: translateY(-.2em) rotate(45deg)
    // span.emoji + .asc-order::after,
    // span.emoji + .desc-order::after
    //   margin-left: 0 !important


      
.input
  width: 100%
  line-height: $input-height
  padding: 0 10px
  font-size: 16px
  outline: none
  border: 1px solid $light-gray
  background: $light-light-gray
  -webkit-appearance: none
  border-radius: $default-border-radius

.form-group
  // padding: 14px 10px 0
  // margin: 2px 0 10px
  position: relative
  margin-bottom: 0
  &__label
    position: absolute
    font-size: 12px
    top: -15px
    left: 15px
  &__input
    @extend .input
    position: relative
    &::placeholder
      color: #b1b1b1

    &--dropdown
      // cursor: pointer
      // color: $dark
      // line-height: 2
      // position: relative
      // user-select: none
      // background: white
      // z-index: 101
      // &::after
      //   position: absolute
      //   width: 8px
      //   height: 8px
      //   content: ""
      //   top: 10px
      //   right: 10px
      //   border-bottom: 2px solid $secondary
      //   border-right: 2px solid $secondary
      //   transform: rotate(45deg)
      &__options
        position: absolute
        display: table
        overflow: hidden
        border-radius: 3px
        box-shadow: $box-shadow
        width: 100%
        top: 100%
        left: 0
        z-index: 100
        user-select: none
        &--default
          top: calc(100% + 4px)
        &__item
          position: relative
          z-index: 1000
          min-width: 100%
          background-color: $light
          color: $dark
          // font-weight: $font-weight-semibold
          padding: .5rem .8rem
          cursor: pointer
          transition: none
          &:last-child
            border-bottom-left-radius: 3px
            border-bottom-right-radius: 3px
          &:hover
            // background-color: $secondary
            p.p
              color: white
          p.p
            line-height: 1.35
            font-size: 16px
            margin-bottom: 0
            em
              color: $dark-gray
              font-style: normal

$reset-size: 22px
.reset
  position: absolute
  top: 1px
  right: 1px
  height: $input-height
  width: $input-height
  border: none
  cursor: pointer
  // background: #ffffff
  outline: none
  display: flex
  align-items: center
  justify-content: center
  &:before
    content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%234a4a4a' width='#{$reset-size}' height='#{$reset-size}' viewBox='0 0 320 512'%3E%3Cpath d='M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z'/%3E%3C/svg%3E")
    display: block
    height: $reset-size
    width: $reset-size

.custom-checkbox
  display: inline-block
  padding-left: 22px
  margin-right: 16px
  position: relative
  cursor: pointer
  &::before
    content: ""
    position: absolute
    left: 0
    top: 1px
    border: 2px solid $dark-gray
    border-radius: 1px
    width: 16px
    height: 16px
    opacity: .85
  &:hover &::before
    opacity: 1
  &--checked
    &::after
      content: ""
      position: absolute
      left: 5px
      top: -5px
      width: 10px
      height: 18px
      border-right: 3px solid $secondary
      border-bottom: 3px solid $secondary
      transform: rotate(45deg)
        


$my-select-circle-size: 16px
$my-select-inner-circle-size: 8px
.my-select
  width: 100%
  display: table
  margin: 10px 0
  &__option
    display: inline-block
    padding-left: 20px
    margin-right: 10px
    cursor: pointer
    position: relative
    &::before
      content: ""
      position: absolute
      top: 0
      left: 0
      width: 16px
      height: 16px
      border: 2px solid $dark-gray
      border-radius: 20px
    &--selected
      &::after
        content: ""
        position: absolute
        top: calc((#{$my-select-circle-size} - #{$my-select-inner-circle-size}) / 2)
        left: calc((#{$my-select-circle-size} - #{$my-select-inner-circle-size}) / 2)
        background-color: $secondary
        width: $my-select-inner-circle-size
        height: $my-select-inner-circle-size
        border-radius: 20px



.mobile-expander
  width: 100%
  height: $mobile-expander-area-height
  position: relative
  z-index: 99
  &__abs-container
    width: 100%
    position: absolute
    top: 0
    left: 0
    display: flex
    flex-direction: column
    align-items: stretch
    box-shadow: 0 7px 10px $box-shadow-color
    @media (prefers-color-scheme: dark)
      box-shadow: 0 10px 12px $box-shadow-color
  &__static-content-container
    width: 100%
    display: table
  &__expanding-content-container
    width: 100%
    background-image: $mobile-expander-gradient
    -webkit-backdrop-filter: blur(20px) //weeeeell
    transition: height .15s
    &.closed
      height: 0
      overflow: hidden
    &.opened
      overflow: scroll
    &.moving
      overflow: hidden
      transition: height 0s
  &__draggable-arrow
    position: relative
    height: $mobile-expander-area-height
    width: 100%
    background-color: $light
    cursor: pointer
    &::after, &::before
      position: absolute
      bottom: calc((#{$mobile-expander-area-height} - #{$mobile-expander-button-height}) / 2)
      content: ""
      border-radius: calc(#{$mobile-expander-button-height} * 2)
      background-color: $dark-gray
      width: calc(#{$mobile-expander-button-width} / 2 + #{$mobile-expander-button-height} / 1.1)
      height: $mobile-expander-button-height
      transition: transform .08s
    &::before
      left: calc((100% - #{$mobile-expander-button-width} - #{$mobile-expander-button-height}) / 2)
      transform-origin: center right
    &::after
      right: calc((100% - #{$mobile-expander-button-width} - #{$mobile-expander-button-height}) / 2)
      transform-origin: center left
    
    &.opened
      &::before
        transform: rotate(-15deg) translate(0, calc(-#{$mobile-expander-button-height} / 2))
      &::after
        transform: rotate(15deg) translate(0, calc(-#{$mobile-expander-button-height} / 2))
      
    &.closed
      &::before
        transform: rotate(15deg) translate(0, calc(#{$mobile-expander-button-height} / 2))
      &::after
        transform: rotate(-15deg) translate(0, calc(#{$mobile-expander-button-height} / 2))

    &.moving
      &::before
        transform: rotate(0deg) translate(0, 0)
      &::after
        transform: rotate(0deg) translate(0, 0)



.about-container
  padding: 10px
  h3.h3
    line-height: 1.5
    margin: 18px 0 8px
    font-weight: 800
    font-size: 1.5em
  p.p
    line-height: 1.45
    margin: 0 0 10px



.flex-container
  height: 100%
  display: flex
  flex-direction: column
  align-items: stretch
  flex-wrap: no-wrap
.flex-column
  flex-grow: 1
  flex-shrink: 0
.scroll-column
  width: 100%
  height: 100%
  overflow-y: scroll
  -webkit-overflow-scrolling: touch
.abs-container
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0



.footer
  // width: 100%
  padding: 4px 10px 10px
  display: table
  text-align: right
  background-color: rgba(0, 0, 0, 0)
  position: absolute
  right: 0
  bottom: 0
  // &::after
  //   position: absolute
  //   top: -50px
  //   left: 0
  //   width: 100%
  //   height: 50px
  //   background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .5) 30%, rgba(255, 255, 255, 1) 100%)
  //   content: ""
  p.link
    display: inline-block
    font-size: $regular-font-size
    cursor: pointer
    color: $link-color



.radio-header
  margin-bottom: 20px
  width: 100%
  &__option
    @extend .noselect
    text-align: center
    font-size: $regular-font-size
    color: $dark-dark-gray
    transition: none !important
  &:not(&--buttons)
    display: flex
    border: 1px solid $light-gray
    border-radius: $default-border-radius
    .radio-header__option
      line-height: $input-height - 4px
      flex-grow: 1
      border-right: 1px solid $light-gray
      cursor: pointer
      background-color: $light
      &:first-of-type
        border-top-left-radius: $default-border-radius - 1px
        border-bottom-left-radius: $default-border-radius - 1px
      &:last-of-type
        border-right: none
        border-top-right-radius: $default-border-radius - 1px
        border-bottom-right-radius: $default-border-radius - 1px
      &--selected
        background-color: $light-light-gray
      .emoji
        margin-left: 5px
        transform: translateY(-1px)
  &--buttons
    display: table
    .radio-header__option
      @extend .button
      @extend .button--active
      margin: 0 8px 8px 0
      display: inline-block
      float: left
      padding-left: 7px
      padding-right: 7px
      &--selected
        // background-color: $secondary
        background-color: rgba(0, 0, 0, 0)
        box-shadow: inset 0px 0px 0px 2px $secondary
        color: $dark
        // border: 3px solid $button-color
        // box-sizing: border-box
      .emoji
        transform: translateX(.1em)





.controls-help
  position: absolute
  bottom: 5px
  right: 0
  width: 100%
  *
    font-weight: 500
    font-size: 16px
  &__desktop
    margin: auto
    display: flex
    padding: 10px 0
    width: 460px
  &__item
    flex-grow: 1
    border-right: 1px solid $light-gray
    padding: 0 0 0 12px
    &__transparent-area
      @extend .noselect
      opacity: .65
      transition: opacity .12s
      &--active
        opacity: 1
    &:last-of-type
      border-right: none
    &__text
      @extend .noselect
      color: $dark-dark-gray
      padding-right: 5px
    &__button
      @extend .noselect
      display: inline
      line-height: 1.2
      padding: 2px 6px
      border-radius: 4px
      background-color: transparent
      transition: background-color .1s
      &--slow-transition
        transition: background-color .5s
      &--active
        transition: background-color .1s
        background-color: $onscreen-button-color


.side-menu-height
  position: absolute
  // top: 35.5%
  top: 37.5%
  height: 25%
  min-height: 180px
  @media (max-width: 1024px)
    top: 30.5%
    right: -6px
    height: 35%


$heat-map-font-size: $regular-font-size
.heat-map
  @extend .side-menu-height
  @extend .noselect
  *
    @extend .noselect
  right: 2px
  width: 40px
  img
    height: calc(100% - #{$heat-map-font-size})
    width: 22%
    position: absolute
    top: calc(#{$heat-map-font-size} / 2)
    right: 30%
    image-rendering: pixelated
    box-shadow: -4px 0 15px rgba(0, 0, 0, .25)
    @media (prefers-color-scheme: dark)
      box-shadow: -4px 0 20px $box-shadow-color
  &__0, &__1, &__2, &__3, &__4
    position: absolute
    font-size: $heat-map-font-size
    line-height: 1
    font-weight: 700
    color: $dark-dark-gray
    transform: rotate(90deg) translateY(-15px)
    transform-origin: top left
  &__0
    top: 0
  &__1
    top: calc((100% - #{$heat-map-font-size}) / 4)
  &__2
    top: calc((100% - #{$heat-map-font-size}) / 2)
  &__3
    top: calc((100% - #{$heat-map-font-size}) / 4 * 3)
  &__4
    bottom: 0
  &__description
    font-size: $heat-map-font-size 
    display: block
    width: 150px
    transform: translate(-35px, 85px) rotate(90deg)
    color: $dark-dark-gray
  &__pointer
    position: absolute
    right: 0
    font-size: $heat-map-font-size * 1.7
    transform: translate(9px, -3px)
    transition: margin-top $node-change-transition



$heat-type-width: 40px
$heat-type-transition: .1s
.heat-type
  @extend .side-menu-height
  left: 25px
  @media (max-width: 1024px)
    left: 8px
  width: $heat-type-width
  display: flex
  flex-direction: column
  justify-content: space-between
  &__button
    width: 80%
    padding-top: 80%
    position: relative
    border-radius: 100px
    overflow: hidden
    // transition: width $heat-type-transition, padding-top $heat-type-transition
    &:before
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      content: ""
      background-color: $onscreen-button-color
      opacity: .5
      // transition: opacity $heat-type-transition
    &__emoji-container
      position: absolute
      width: 100%
      height: 100%
      top: 1px
      left: 0
      span.emoji
        padding: 0
        font-size: 18px
        line-height: calc(#{$heat-type-width} * .8)
        transform: translateX(1.5px)
        // transition: font-size $heat-type-transition, line-height $heat-type-transition, transform $heat-type-transition
    &--active
      width: 100%
      padding-top: 100%
      &:before
        opacity: 1
    &--active &__emoji-container
      span.emoji
        font-size: 27px
        line-height: $heat-type-width
        transform: translateX(0px)


.emoji
  // font-size: $regular-font-size
  font-size: inherit
  // padding-left: 5px
  display: inline-block
  &__kiss
    transform: translateY(.07em)
  &__world
    transform: translateY(.07em)
  &__folder
    transform: translateY(.1em)
  &__img
      transform: translate(-.05em, -.1em)
      height: 1em
      width: auto




.kiss-day
  width: 100%
  margin: 15px 0
  &__date
    width: 100%
    font-size: 12px
    line-height: 1.6
  &__entries
    width: 100%
    &__item
      width: 100%
      // font-size: $regular-font-size
      &:nth-child(odd)
        background-color: $apple-item-gray
      &__time
        font-size: $regular-font-size
        display: inline-block
        width: 50px
        padding-left: 5px
        color: $dark-gray
      .emoji
        margin-left: 5px


.List
  width: 100%
  &__Item
    width: 100%
    // @extend .d-flex
    // @extend .justify-content-between
    &:nth-child(odd)
      background-color: $apple-item-gray


.secret
  padding: 0 5px
  margin-bottom: 10px
  &__link
    display: inline-block
    // width: 100%
    font-size: $regular-font-size
    line-height: 20px
    color: $light-gray !important
    margin-right: 10px



//SCROLLBAR
// /* total width */
// body::-webkit-scrollbar
//   background-color: $light
//   width: 16px

// /* background of the scrollbar except button or resizer */
// body::-webkit-scrollbar-track
//   background-color: $light

// /* scrollbar itself */
// body::-webkit-scrollbar-thumb
//   background-color: $light
//   border-radius: 16px
//   border: 4px solid $dark

// /* set button(top and bottom of the scrollbar) */
// body::-webkit-scrollbar-button 
//   display: none
::-webkit-scrollbar
  width: 0
  height: 0


.powered-by-nginx
  @extend .noselect
  position: absolute
  top: 15px
  right: 12px
  color: $dark-dark-gray
  font-weight: 400
  text-align: right
  line-height: 1.5


.loader
  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape)
    transform: rotate(-90deg)
    transform-origin: left top
    width: 100vh
    height: 100vw
    overflow-x: hidden
    position: absolute
    top: 100%
    left: 0
  display: flex
  @extend .flex-column
  @extend .justify-content-between
  @extend .align-items-center
  width: 100vw
  height: 100vh
  position: relative
  background-color: $light
  z-index: 20000
  &__container
    @extend .d-flex
    @extend .flex-column
    @extend .justify-content-between
    @extend .align-items-center
    max-width: 800px
    width: 100%
    height: 100%
    padding: 50px 30px 30px
    @media screen and ( max-width: 1024px )  
      padding: 25px 35px 130px
    @media screen and ( max-width: 320px )
      transform: scale(.75) translateY(-10%)
    @media screen and ( max-width: 568px ) and (orientation: landscape)
      transform: scale(.75) translateY(-10%)
  &__emoji__container
    width: 25%
    padding-top: 25%
    @media screen and ( max-width: 1024px )  
      width: 30%
      padding-top: 30%
      margin-bottom: 10%
    position: relative
    .emoji
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      animation: flash .75s ease-in-out
      @keyframes flash
        0%
          opacity: 0.5
        50%
          opacity: 1
        100%
          opacity: 0.5
  &__text
    @extend .d-flex
    @extend .flex-column
    @extend .justify-content-center
    @extend .align-items-center
    .h1 
      @extend .gradient-text
      @extend .gradient-text-animation-0
      margin-bottom: 40px
      @media screen and ( max-width: 1024px )  
        font-size: $regular-font-size * 2.25
        margin-bottom: 20px
    .h4
      margin-bottom: 20px
      @media screen and ( max-width: 1024px )  
        font-size: $regular-font-size * 1.2
        margin-bottom: 10px
    .ul
      padding-left: 22px
      .li
        font-size: $regular-font-size * 1.2
        margin-bottom: 10px
        line-height: 1.5
        @media screen and ( max-width: 1024px )  
          font-size: $regular-font-size * .9
    .button
      @extend .button--active
      margin: 0 10px 8px 0
      display: inline-block
      padding-left: 10px
  &__by
    @extend .h3
    @media screen and ( max-width: 1024px )
      font-size: $regular-font-size * 1.4



.custom-date-picker
  width: 100%
  display: table
  margin-bottom: 20px
  &__label
    margin-bottom: 12px
  &__inputs
    &__item
      display: block
      float: left
      // width: 50px
      // background-color: blue
      margin: 0 3px
      input
        border: none
        padding: 0 2px
        float: left
        border-radius: 0
        background: none
        border-bottom: 1px solid $dark
        height: 25px
      .form-group__label
        top: -8px
        left: 3px
      &--year
        margin-right: 20px
        width: 43px
      &--month
        width: 26px
      &--day
        width: 26px
      &--hour
        width: 26px
      &--minute
        width: 26px
    &__after
      display: block
      float: left
      line-height: 25px
      // background-color: red
      position: relative
  &__res
    float: left
    line-height: 25px
    margin-left: 20px


.link
  &__short-name
    @extend .d-inline-block
    max-width: 100px
    height: 20px
    line-height: 20px
    text-overflow: ellipsis
    overflow-x: hidden
    overflow-y: visible
    white-space: nowrap
    color: inherit
    font-size: inherit
    margin-right: -3px
    text-decoration: inherit

a.edge-link
  margin-left: .75rem
  margin-right: .75rem
  text-decoration: none
  color: $dark
  position: relative
  &:hover
    text-decoration: none
    color: $dark
  &::after
    content: ''
    position: absolute
    width: 100%
    height: 1px
    background-color: $dark
    bottom: 0
    left: 0




.Login
  position: absolute
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  background-color: $light
  color: $dark
  @extend .d-flex
  &__authorize
    @extend .input
    @extend .d-flex
    @extend .flex-row
    @extend .align-items-center
    @extend .justify-content-center
    width: 100%
    &:hover
      text-decoration: none
    *
      @extend .text-center
      line-height: 32px
      color: $dark
      text-decoration: none
      &:hover
        text-decoration: none
        color: $dark
    &__logo
      height: 20px
      width: auto
      @extend .d-block
      
